import './lib/common.js';
import barba from '@barba/core'; // Ajax
import {TimelineMax} from 'gsap'; // Анимация
import Swiper from 'swiper'; // Карусель
import fancybox from '@fancyapps/fancybox'; // Модальные окна
import lazyload from 'jquery-lazyload'; // Отложенная зарузка изображений

var h = window.innerHeight;

let tl = new TimelineMax;

tl
  .fromTo('.loader', 1,{height: h, y: 0}, {y:0, height: 0, ease: Power3.easeIn})
  .fromTo('.sc-logo-wrapper', 1, {y:-30, opacity: 0}, {y:0, opacity: 1, ease: Power3.easeOut},0.75)
  .staggerFromTo('.sc-nav-item', 0.5, {y:-30, opacity: 0}, {y:0, opacity: 1, ease: Power3.easeOut}, 0.05, '-=.25')
  .fromTo('.sc-trigger', 0.5, {y:-30, opacity: 0}, {y:0, opacity: 1, ease: Power3.easeOut}, 1.3)
  .fromTo('.sc-cart-wrapper', 0.5, {y:-30, opacity: 0}, {y:0, opacity: 1, ease: Power3.easeOut}, 1.45)
  .staggerFromTo('.header-socials-item', 0.5, {y:30, opacity: 0}, {y:0, opacity: 1, ease: Power3.easeOut}, 0.05)
  .fromTo('.header-wrapper-content', 0.75, {x:-30, opacity: 0}, {x:0, opacity: 1, ease: Power3.easeOut}, 1.5);


function scrollContent() {
  var headerHeight = $('.header').height(),
    scrollContent = $('.scroll-content').height(),
    th = $(window).scrollTop();

  if (!headerHeight) {
    $('.scroll-content').addClass('invert');
  } else {
    $('.scroll-content').removeClass('invert');
  }
  if (th > (headerHeight - (scrollContent / 2))) {
    $('.to-top').addClass('is-active');
  } else {
    $('.to-top').removeClass('is-active');
  }
}

scrollContent();

$(window).scroll(function() {
  scrollContent();
});

$('.sc-trigger').on('click', function(e) {
  e.preventDefault;
  $('.scroll-content').toggleClass('sc-trigger-active');
  $(this).toggleClass('sc-trigger-active');
  $('.sc-nav').toggleClass('sc-nav-active');
});

function scrollTop() {
  var btn = $('.to-top');

  btn.on('click', function(e) {
    e.preventDefault();
    $('html, body').animate({scrollTop: 0}, 800);
  });

  $('a.button-scroll').click(function() {
    var elementClick = $(this).attr('href');
    var destination = $(elementClick).offset().top;
    $('html, body').animate({scrollTop: destination }, 800);
    return false; 
  });
}
scrollTop();

// Barba
barba.init({
  prevent: ({ el }) => el.classList && el.parentElement.classList.contains('fm-panel'),
  transitions: [{
    leave: function(data) {
      
      
      var done = this.async();
      tl
        .fromTo('.loader', 1,{height: 0, y: h}, {y:0, height: h, ease: Power3.easeIn, onComplete: this.async()});

    },
    enter: function(data) {
    	window.scrollTo(0, 0);
      var h = window.innerHeight;
      
      swiper();
      scrollTop();
      $('.sc-trigger').removeClass('sc-trigger-active');
      $('.sc-nav').removeClass('sc-nav-active');
      $('.scroll-content').removeClass('sc-trigger-active');

      tl
        .to('.loader', 1, {height: 0, y: 0, ease: Power3.easeOut, onComplite: function() {
          scrollContent();
        }});
      
    }
  }]
});

// LazyLoad
$('img.lazyload').lazyload({
  threshold : 1080,
  effect : 'fadeIn'
});

// Modals
$('[data-fancybox]').on('click', function() {
  barba.destroy();
}).fancybox({
  loop: true,
  autoFocus: false,
  touch: {
    vertical: false
  },
  buttons: [
    'zoom',
    'close'
  ],
});

var swiper = () => {
  var mySwiper = new Swiper('.products-wrapper', {
    init: true,
    speed: 400,
    spaceBetween: 20,
    freeMode: true,
    slidesPerView: 4,
    parallax: true,
    lazy: true,
    hashNavigation: true,
    watchOverflow: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    scrollbar: {
      el: '.swiper-scrollbar',
    },
    breakpoints: {
      375: {
        slidesPerView: 1.2
      },
      440: {
        slidesPerView: 1.5
      },
      620: {
        slidesPerView: 2.2
      },
      800: {
        slidesPerView: 3
      }
    }
  });

  var myRadioSwiper = new Swiper('.radio-select', {
    init: true,
    speed: 400,
    spaceBetween: 20,
    freeMode: true,
    slidesPerView: 4,
    hashNavigation: true,
    watchOverflow: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  });

  var myGallerySwiper = new Swiper('.gallery-wrapper', {
    init: true,
    speed: 400,
    spaceBetween: 20,
    freeMode: true,
    slidesPerView: 3,
    parallax: true,
    lazy: true,
    hashNavigation: true,
    watchOverflow: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    scrollbar: {
      el: '.swiper-scrollbar',
    },
    breakpoints: {
      320: {
        slidesPerView: 1
      },
      480: {
        slidesPerView: 2
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 10
      }
    }

  });
};

swiper();

// Modals
$('[data-fancybox]').fancybox({
  loop: true,
  autoFocus: false,
  touch: {
    vertical: false
  },
  buttons: [
    'zoom',
    'close'
  ],
});
